export const config = {
  screens: {
    UnauthenticatedNavigator: {
      screens: {
        onboarding: {
          initialRouteName: "landing",
          path: "onboarding",
          screens: {
            landing: "landing",
            phone: "phone",
            code: "code",
            profile: "profile",
          },
        },
      },
    },
    Home: {
      initialRouteName: "events",

      screens: {
        shop: "shop",
        events: "events",
        profile: {
          screens: {
            Profile: "profile",
            EditProfile: "edit-profile",
            Partner: "partner/:partnerId",
            Perk: "partner/:partnerId/perk/:perkId",
          },
        },
      },
    },

    EventDetail: "events/:eventId",
    EventShare: "rides/:eventId",
    UserProfile: "members/:userId",
    ComingSoon: "coming-soon",
  },
};
