import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Text, View, XStack } from "tamagui";

import { brandShadowSm, customColorTokens } from "../../../tamagui.config";
import { Header } from "../../components/design-system/Header";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { graphql } from "relay-hooks";

import { Loader } from "../../components/design-system/Loader";

import { useAtomValue, useSetAtom } from "jotai";
import { currentUserAtom } from "../../../atoms/global";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import { RidesNavigatorParamList } from "../../navigators/RidesNavigator";

import { PlusIcon } from "react-native-heroicons/solid";

import { EventsList } from "./components/EventsList";
import { Button } from "../../components/design-system";
import { CommunityRidesQuery } from "./__generated__/CommunityRidesQuery.graphql";
import { communityRidesQueryAtom } from "../../features/Rides/atoms/callbacks";
import { ConfirmationModal } from "../../components/design-system/ConfirmationModal";
import { useStravaAuth } from "../../features/Oauth";
import { useCreateAuthToken } from "../../features/Oauth/hooks/useCreateAuthToken";
import { AuthSessionResult } from "expo-auth-session";
import { CalendarIcon } from "../../components/design-system/icons";

export const CommunityRides = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const [queryRef, loadQuery] = useQueryLoader<CommunityRidesQuery>(
    CommunityRidesQueryString
  );

  useEffect(() => {
    loadQuery(
      {
        id: currentUser?.id ?? "",
      },
      { fetchPolicy: "network-only" }
    );
  }, [loadQuery, currentUser?.id]);

  const refreshQuery = () => {
    loadQuery(
      {
        id: currentUser?.id ?? "",
      },
      { fetchPolicy: "network-only" }
    );
  };
  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? (
        <CommunityRidesInner refreshQuery={refreshQuery} queryRef={queryRef} />
      ) : null}
    </Suspense>
  );
};

export const CommunityRidesInner = ({
  queryRef,
  refreshQuery,
}: {
  queryRef: PreloadedQuery<CommunityRidesQuery>;
  refreshQuery: () => void;
}) => {
  const data = usePreloadedQuery<CommunityRidesQuery>(
    CommunityRidesQueryString,
    queryRef
  );
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const authToken = data.auth_tokensCollection?.edges?.[0]?.node;

  const navigation = useNavigation<NavigationProp<RidesNavigatorParamList>>();

  const { response, promptAsync, getToken } = useStravaAuth();
  const user = useAtomValue(currentUserAtom);

  const [handleCreateAuthToken] = useCreateAuthToken(() => {
    handleGetToken();
    refreshQuery();
  });

  const handleStravaResponse = useCallback(
    (res: AuthSessionResult, userId: string) => {
      if (res.type === "success") {
        const { code } = res.params;
        handleCreateAuthToken({
          code,
          provider: "Strava",
          profile_id: userId,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (response && response.type === "success") {
      handleStravaResponse(response, user?.id ?? "");
    }
  }, [response, handleStravaResponse]);

  const handleGetToken = async () => {
    await getToken();
  };

  const setCommuntiyRidesQuery = useSetAtom(communityRidesQueryAtom);

  setCommuntiyRidesQuery(() => {});

  const handleHostPress = () => {
    if (authToken) {
      navigation.navigate("CreateRide");
    } else {
      setIsAuthModalOpen(true);
    }
  };

  const startStravaSync = () => {
    setIsAuthModalOpen(false);
    promptAsync();
  };

  return (
    <View
      flex={1}
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <Header
        title={"Rides"}
        headerRight={
          <XStack gap="$2" alignItems="center">
            <Button
              py={4}
              h={32}
              w={32}
              bg="$brandParchment"
              pressStyle={{ backgroundColor: "$brandParchment" }}
              onPress={() => navigation.navigate("Calendar")}
              {...brandShadowSm}
            >
              <CalendarIcon width={24} height={24} />
            </Button>
            <Button onPress={handleHostPress} py={4} {...brandShadowSm} h={32}>
              <XStack alignItems="center">
                <Text>Host</Text>
                <PlusIcon width={16} height={16} color="black" />
              </XStack>
            </Button>
          </XStack>
        }
      />

      <EventsList />
      <ConfirmationModal
        isOpen={isAuthModalOpen}
        title="Connect Strava"
        subtitle="To host a ride, you need to connect your Strava account."
        yesMessage="Connect Strava"
        noMessage="No, I don't want to host"
        onConfirm={() => startStravaSync()}
        onCancel={() => setIsAuthModalOpen(false)}
      />
    </View>
  );
};

const CommunityRidesQueryString = graphql`
  query CommunityRidesQuery($id: UUID!) {
    auth_tokensCollection(
      filter: { profile_id: { eq: $id }, provider: { eq: Strava } }
      first: 1
    ) {
      edges {
        node {
          id
          code
          token
          expires_at
        }
      }
    }
  }
`;
