import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../atoms/global";
import { supabase } from "../../lib/supabase";
import { useState } from "react";

export const useUnFollowUser = () => {
  const currentUser = useAtomValue(currentUserAtom);

  const [isLoading, setIsLoading] = useState(false);

  const handleUnFollowUser = async (userId: string, callback: () => void) => {
    setIsLoading(true);
    await supabase
      .from("profile_followers")
      .delete()
      .eq("followed_id", userId)
      .eq("follower_id", currentUser?.id);
    callback();
    setIsLoading(false);
  };

  return [handleUnFollowUser, isLoading] as const;
};
