/**
 * @generated SignedSource<<0474fc55f45418ffa73e354705eebbe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteCardFragment$data = {
  readonly distance: number | null | undefined;
  readonly elevation: number | null | undefined;
  readonly id: any;
  readonly image_url: string | null | undefined;
  readonly name: string | null | undefined;
  readonly strava_id: string | null | undefined;
  readonly " $fragmentType": "RouteCardFragment";
};
export type RouteCardFragment$key = {
  readonly " $data"?: RouteCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RouteCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RouteCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elevation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "distance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "strava_id",
      "storageKey": null
    }
  ],
  "type": "routes",
  "abstractKey": null
};

(node as any).hash = "9ba6c904d249b8b76b738f5cfc7def8a";

export default node;
