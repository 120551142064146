import { Suspense, useEffect } from "react";
import { Loader } from "../../../components/design-system/Loader";
import { EventDetail, EventDetailQueryString } from "./EventDetail";
import { RouteProp, useRoute } from "@react-navigation/native";
import { BaseNavigatorParamList } from "../../../navigators/BaseNavigator";
import { useQueryLoader } from "react-relay";
import { EventDetailQuery } from "./__generated__/EventDetailQuery.graphql";

export const EventDetailQueryRenderer = () => {
  const route = useRoute<RouteProp<BaseNavigatorParamList, "EventDetail">>();

  const eventId = route.params.eventId;

  const [queryRef, loadQuery] = useQueryLoader<EventDetailQuery>(
    EventDetailQueryString
  );

  useEffect(() => {
    if (eventId) {
      loadQuery({ id: eventId }, { fetchPolicy: "network-only" });
    }
  }, [loadQuery, eventId]);

  const refreshQuery = () => {
    loadQuery({ id: eventId }, { fetchPolicy: "network-only" });
  };

  return (
    <Suspense fallback={<Loader />}>
      {queryRef ? (
        <EventDetail queryRef={queryRef} refreshQuery={refreshQuery} />
      ) : null}
    </Suspense>
  );
};
