import { View, Heading, Text, YStack } from "tamagui";
import Avatar from "./Avatar";
import { StarIcon } from "./design-system/icons";
import { graphql, useFragment } from "relay-hooks";
import { ProfileCard_fragment$key } from "./__generated__/ProfileCard_fragment.graphql";
import { FollowButton } from "./FollowButton";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../atoms/global";

type ProfileCardProps = {
  fragmentRef: ProfileCard_fragment$key | null;
};

export const ProfileCard = ({ fragmentRef }: ProfileCardProps) => {
  const data = useFragment(ProfileCardFragment, fragmentRef);
  const role = data?.user_rolesCollection?.edges?.[0]?.node?.role ?? "Member";
  const roleDisplay =
    role === "PaidMember" ? "Club" : role.split(/(?=[A-Z])/).join(" ");
  const currentUser = useAtomValue(currentUserAtom);
  const isCurrentUser = currentUser?.id === data?.id;

  return (
    <YStack
      justifyContent="center"
      alignItems="center"
      pos="relative"
      p="$4"
      borderRadius={15}
      gap="$6"
      display="flex"
      flexDirection="column"
      w="100%"
      h={350}
    >
      <Avatar
        size={150}
        imageStyle={{
          borderRadius: 999,
        }}
        url={data?.avatar_url ?? null}
      />

      <YStack gap="$1" justifyContent="center" alignItems="center">
        <Heading size="$2" textTransform="uppercase" textAlign="center">
          {data?.name}
        </Heading>

        <Text fontFamily="$heading" fontSize={16} mt="$2">
          Favorite Breakfast Food
        </Text>
        <Text>{data?.breakfast_food}</Text>
      </YStack>

      {isCurrentUser ? null : <FollowButton userId={data?.id} />}

      {role !== "Member" ? (
        <View
          pos="absolute"
          bottom={0}
          right={0}
          borderRadius={999}
          p="$1"
          justifyContent="space-between"
          alignItems="center"
        >
          <StarIcon width={50} height={50} />
          <Heading fontSize={12} textTransform="uppercase" fontStyle="italic">
            {roleDisplay}
          </Heading>
        </View>
      ) : null}
    </YStack>
  );
};

export const ProfileCardFragment = graphql`
  fragment ProfileCard_fragment on profiles {
    id
    name
    avatar_url
    bio
    strava_code
    breakfast_food
    instagram
    user_rolesCollection {
      edges {
        node {
          role
        }
      }
    }
  }
`;
