import React from "react";
import { Text, View } from "tamagui";

import { customColorTokens } from "../../../tamagui.config";
import { Header } from "../../components/design-system/Header";

import { MySchedule } from "./components/MySchedule";
import { ArrowLeft } from "@tamagui/lucide-icons";
import { Pressable } from "react-native";
import { RidesNavigatorParamList } from "../../navigators/CreateARideNavigator";
import { NavigationProp, useNavigation } from "@react-navigation/native";

export const MyCalendar = () => {
  const navigation = useNavigation<NavigationProp<RidesNavigatorParamList>>();
  return (
    <View
      flex={1}
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
    >
      <Header
        title={
          <Pressable onPress={() => navigation.goBack()}>
            <ArrowLeft />
          </Pressable>
        }
        headerCenter={<Text fontSize={16}>Your Ride Calendar</Text>}
        headerRight={<View />}
      />

      <MySchedule />
    </View>
  );
};
