import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { FollowButtonInternalQuery } from "./__generated__/FollowButtonInternalQuery.graphql";
import { useFollowUser } from "../../hooks/useFollowUser";
import { useUnFollowUser } from "../../hooks/useUnfollowUser";
import { useCallback, useEffect, useState } from "react";
import { HeaderButton } from "../design-system/Button/HeaderButton";
import { UserMinus2, UserPlus2 } from "@tamagui/lucide-icons";
import { ArrowPathIcon } from "react-native-heroicons/solid";
import { supabase } from "../../../lib/supabase";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../atoms/global";

type Props = {
  queryRef: PreloadedQuery<FollowButtonInternalQuery>;
  userId: string;
  reloadQuery: () => void;
};

export const FollowButtonInternal = ({
  queryRef,
  userId,
  reloadQuery,
}: Props) => {
  const currentUser = useAtomValue(currentUserAtom);

  const data = usePreloadedQuery(FollowButtonInternalQueryString, queryRef);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchFriendship = useCallback(async () => {
    setIsFetching(true);
    const res = await supabase
      .from("profile_followers")
      .select("id")
      .eq("follower_id", currentUser?.id)
      .eq("followed_id", userId)
      .single();
    setIsFollowing(!!res.data?.id);
    setIsFetching(false);
  }, [currentUser?.id, userId, setIsFetching, setIsFollowing]);

  useEffect(() => {
    // setIsFetching(true);
    fetchFriendship();
  }, [fetchFriendship]);

  const [followUser, isSendingFollowMutation] = useFollowUser();
  const [unfollowUser, isSendingUnFollowMutation] = useUnFollowUser();

  const handlePress = () => {
    if (isFollowing) {
      unfollowUser(userId, () => {
        reloadQuery();
        setIsFollowing(false);
      });
    } else {
      followUser(userId, () => {
        reloadQuery();
        setIsFollowing(true);
      });
    }
  };

  const isLoading =
    isFetching || isSendingFollowMutation || isSendingUnFollowMutation;

  return (
    <HeaderButton onPress={handlePress} disabled={isLoading}>
      {isLoading ? (
        <ArrowPathIcon color="black" width={16} height={16} />
      ) : isFollowing ? (
        <UserMinus2 color="black" width={16} height={16} />
      ) : (
        <UserPlus2 color="black" width={16} height={16} />
      )}
    </HeaderButton>
  );
};

export const FollowButtonInternalQueryString = graphql`
  query FollowButtonInternalQuery($currentUserId: UUID!, $userId: UUID!) {
    profile_followersCollection(
      filter: {
        follower_id: { eq: $currentUserId }
        followed_id: { eq: $userId }
      }
      first: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
