import { useState } from "react";
import { Button, Heading, Label, Text, View, YStack } from "tamagui";

import { iProfile } from "../../types";
import { Input, TextArea } from "../../../../components/design-system";
import Avatar from "../../../../components/Avatar";

import { Loader } from "../../../../components/design-system/Loader";
import { useUploadAvatar } from "../../../../hooks/useUploadAvatar";

type EditProfileProps = {
  onChange: (val: iProfile) => void;
  defaultValues: iProfile;
  children?: React.ReactNode;
  id: string;
};

export const EditProfile = ({
  onChange,
  defaultValues,
  children,
  id,
}: EditProfileProps) => {
  const {
    name,
    breakfast_food,
    avatar_url,
    emergency_contact_name,
    emergency_contact_number,
  } = defaultValues;

  const [avatarUrl, setAvatarUrl] = useState(avatar_url);

  const onAvatarUpload = (url: string) => {
    setAvatarUrl(url);

    onChange({ ...defaultValues, avatar_url: url });
  };

  const { uploadAvatar, avatarUploading } = useUploadAvatar({
    onAvatarUpload,
    userId: id,
  });

  return (
    <View w="100%" flex={1}>
      {avatarUploading ? <Loader /> : null}
      <View w="100%" justifyContent="center">
        <View justifyContent="center" alignItems="center">
          {id ? (
            <Avatar
              height={200}
              width={200}
              url={avatarUrl ?? null}
              imageStyle={{ borderRadius: 999 }}
            />
          ) : null}
        </View>

        <Button
          unstyled
          chromeless
          pressStyle={{
            backgroundColor: "transparent",
          }}
          alignItems="center"
          justifyContent="center"
          onPress={uploadAvatar}
          mt="$2"
          borderWidth={1}
        >
          <Text>Edit Photo</Text>
        </Button>
      </View>
      <YStack gap="$1" px="$4">
        <View>
          <Label>Full Name</Label>
          <Input
            placeholder="Full Name"
            defaultValue={name ?? ""}
            onChangeText={(text) => onChange({ ...defaultValues, name: text })}
          />
        </View>
        {/* <View>
          <Label>Bio</Label>
          <TextArea
            placeholder="Tell us about yourself"
            value={bio ?? ""}
            onChangeText={(text) => onChange({ ...defaultValues, bio: text })}
          />
        </View> */}
        <View>
          <Label>Favorite Breakfast Food</Label>
          <TextArea
            placeholder="Waffles, Pancakes, Eggs, etc."
            defaultValue={breakfast_food ?? ""}
            onChangeText={(text) =>
              onChange({ ...defaultValues, breakfast_food: text })
            }
          />
        </View>

        {/* <View>
          <Label>Instagram</Label>
          <TextArea
            placeholder="EX: @planetzolon"
            value={instagram ?? ""}
            onChangeText={(text) =>
              onChange({ ...defaultValues, instagram: text })
            }
          />
        </View> */}

        <Heading size="$4" textTransform="uppercase" mt="$4">
          Safety
        </Heading>

        <YStack gap="$1">
          <View>
            <Label>Emergency Contact Name</Label>
            <TextArea
              placeholder="Mom, dad"
              defaultValue={emergency_contact_name ?? ""}
              onChangeText={(text) =>
                onChange({ ...defaultValues, emergency_contact_name: text })
              }
            />
          </View>

          <View>
            <Label>Emergency Contact Number</Label>
            <TextArea
              placeholder="555-555-5555"
              defaultValue={emergency_contact_number ?? ""}
              onChangeText={(text) =>
                onChange({ ...defaultValues, emergency_contact_number: text })
              }
            />
          </View>
        </YStack>
      </YStack>

      {children}
    </View>
  );
};
