import { graphql } from "relay-hooks";
import { format } from "date-fns";
import { Text, View } from "tamagui";
import { EventCard } from "../../../components/design-system/EventCard";
import { SectionList } from "react-native";
import { customColorTokens } from "../../../../tamagui.config";
import { Button } from "../../../components/design-system";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { HomeTabsParamList } from "../../../navigators/HomeTabsNavigator";
import { Event } from "../../../features/Events/types";
import { useEffect, useState } from "react";
import { supabase } from "../../../../lib/supabase";
import { currentUserAtom } from "../../../../atoms/global";
import { useAtomValue } from "jotai";

export const MySchedule = () => {
  const [events, setEvents] = useState<Event[] | null>(null);

  const isFocused = useIsFocused();

  const user = useAtomValue(currentUserAtom);

  const today = new Date();
  const date = format(today, "MM-dd-yy");

  useEffect(() => {
    if (isFocused) {
      supabase
        .from("events")
        .select(
          "*, routes(*), attendances!inner(user_id), profiles(avatar_url, name)"
        )
        .eq("attendances.user_id", user?.id)
        .filter("date", "gte", date)
        .order("date", { ascending: true })
        .then(({ data }) => {
          const eventMap =
            data?.map(
              (event: any) =>
                ({
                  ...event,
                  route: event.routes,
                }) as Event
            ) ?? [];
          return setEvents(eventMap ?? []);
        });
    }
  }, [isFocused, setEvents]);

  const groupedData = events?.reduce((acc: any, curr) => {
    const eventMonthYear = format(new Date(curr.date), "MMMM yyyy");
    if (!acc[eventMonthYear]) {
      acc[eventMonthYear] = [];
    }
    acc[eventMonthYear].push(curr);
    return acc;
  }, {});

  const sections = groupedData
    ? Object.keys(groupedData).map((key) => ({
        title: key,
        data: groupedData[key],
      }))
    : [];

  const renderSectionHeader = ({
    section: { title },
  }: {
    section: {
      title: string;
    };
  }) => (
    <View bg="$brandParchment" p="$4">
      <Text fontSize={20} fontFamily="$heading">
        {title}
      </Text>
    </View>
  );

  const renderItem = ({ item }: { item: any }) => {
    return (
      <View px="$4" mb="$4">
        <EventCard event={item} />
      </View>
    );
  };

  const navigation = useNavigation<NavigationProp<HomeTabsParamList>>();

  return (
    <SectionList
      sections={sections}
      keyExtractor={(item) => item.id}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
      ListEmptyComponent={
        <View
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
          minHeight={300}
          gap="$4"
        >
          <Text fontSize={20}>{`Looks like your schedule is clear!`}</Text>
        </View>
      }
      contentContainerStyle={{
        paddingBottom: 100,
      }}
    />
  );
};

export const MyScheduleListQueryString = graphql`
  query MyScheduleListQuery($userId: UUID!) {
    attendancesCollection(filter: { user_id: { eq: $userId } }) {
      edges {
        node {
          id
          events {
            id
            date
          }
        }
      }
    }
  }
`;
