import { Pressable, SectionList } from "react-native";
import { customColorTokens } from "../../../../tamagui.config";
import { Text, View, XStack } from "tamagui";

import { format } from "date-fns";
import { EventCard } from "../../../components/design-system/EventCard";
import { paceOptions, terrainOptions } from "./Filters";
import { supabase } from "../../../../lib/supabase";
import { useEffect, useState } from "react";
import { useIsFocused } from "@react-navigation/native";

import { Event, Group } from "../../../features/Events/types";
import { Loader } from "../../../components/design-system/Loader";
import { Pill } from "../../../components/design-system/Pill";
import { CloseIcon } from "../../../components/design-system/icons";

export const EventsList = () => {
  const [paceFilter, setPaceFilter] = useState<Group | null>(null);
  const [terrainFilter, setTerrainFilter] = useState<string | null>(null);

  const handlePacePress = (pace: string) => {
    setPaceFilter(paceFilter === pace ? null : (pace as Group));
  };

  const handleTerrainPress = (terrain: string) => {
    setTerrainFilter(terrain === terrainFilter ? null : terrain);
  };

  const [events, setEvents] = useState<Event[] | null>(null);

  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(false);

  const today = new Date();
  const date = format(today, "MM-dd-yy");

  useEffect(() => {
    if (isFocused) {
      !events && setLoading(true);
      supabase
        .from("events")
        .select("*, routes(*), profiles(avatar_url, name)")
        .eq("type", "ClubRide")
        .order("date", { ascending: true })
        .filter("date", "gte", date)
        .in("terrain", terrainFilter ? [terrainFilter] : terrainOptions)
        .overlaps("groups", paceFilter ? [paceFilter] : paceOptions)
        .then(({ data }) => {
          const eventMap =
            data?.map(
              (event: any) =>
                ({
                  ...event,
                  route: event.routes,
                }) as Event
            ) ?? [];
          setLoading(false);

          setEvents(eventMap);
        });
    }
  }, [isFocused, terrainFilter, paceFilter, setEvents]);

  const groupedData = events?.reduce((acc: any, curr) => {
    const eventMonthYear = format(new Date(curr.date), "MMMM yyyy");
    if (!acc[eventMonthYear]) {
      acc[eventMonthYear] = [];
    }
    acc[eventMonthYear].push(curr);
    return acc;
  }, {});

  const newSections = groupedData
    ? Object.keys(groupedData).map((key) => ({
        title: key,
        data: groupedData[key],
      }))
    : [];

  const renderSectionHeader = ({
    section: { title },
  }: {
    section: {
      title: string;
    };
  }) => (
    <View bg="$brandParchment" p="$4">
      <Text fontSize={20} fontFamily="$heading">
        {title}
      </Text>
    </View>
  );

  const renderItem = ({ item }: { item: any }) => {
    return (
      <View px="$4" mb="$4">
        <EventCard event={item} />
      </View>
    );
  };

  return (
    <View flex={1}>
      {loading ? <Loader /> : null}
      <SectionList
        ListHeaderComponent={() => (
          <View pt="$4" bg="$brandParchment">
            <XStack
              gap="$2"
              bg="$brandParchment"
              pb="$2"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="flex-start"
              px="$4"
            >
              {terrainOptions.map((t) => (
                <Pressable onPress={() => handleTerrainPress(t)} key={t}>
                  <Pill
                    icon={
                      terrainFilter === t ? (
                        <CloseIcon
                          fill="black"
                          color="black"
                          width={12}
                          height={12}
                        />
                      ) : null
                    }
                    text={t}
                    bg={
                      terrainFilter === t ? "$brandYellow" : "$brandParchment"
                    }
                    color={"black"}
                    borderColor={terrainFilter === t ? "black" : "black"}
                  />
                </Pressable>
              ))}
              {paceOptions.map((p, i) => (
                <Pressable onPress={() => handlePacePress(p)} key={i}>
                  <Pill
                    icon={
                      paceFilter === p ? (
                        <CloseIcon
                          fill="black"
                          color="black"
                          width={12}
                          height={12}
                        />
                      ) : null
                    }
                    text={p}
                    bg={paceFilter === p ? "$brandYellow" : "$brandParchment"}
                    color={"black"}
                    borderColor={paceFilter === p ? "black" : "black"}
                  />
                </Pressable>
              ))}
            </XStack>
          </View>
        )}
        sections={newSections}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        stickySectionHeadersEnabled={true}
        style={{ backgroundColor: customColorTokens.brandParchment }}
        contentContainerStyle={{
          paddingBottom: 100,
        }}
        ListEmptyComponent={
          <View
            flex={1}
            justifyContent="center"
            alignItems="center"
            h="100%"
            minHeight={300}
            gap="$4"
          >
            <Text fontSize={20}>{`Looks like your schedule is clear!`}</Text>
          </View>
        }
      />
    </View>
  );
};
