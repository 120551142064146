/**
 * @generated SignedSource<<87bb49dfd4a74dbd3f03fc9648747c2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventType = "Babes" | "CentralMachineWorks" | "ClubRide" | "CommunityEvent" | "CommunityRide" | "GravelGang" | "PopUp" | "SwimClub" | "TheMeteorCafe" | "%future added value";
export type Pace = "A" | "B" | "C" | "D" | "Party" | "%future added value";
export type Terrains = "Gravel" | "MTB" | "Mixed" | "Road" | "%future added value";
export type UserRole = "Member" | "PaidMember" | "RideLeader" | "Team" | "%future added value";
export type EventShareQuery$variables = {
  id: any;
};
export type EventShareQuery$data = {
  readonly eventsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly date: any | null | undefined;
        readonly description: string | null | undefined;
        readonly groups: ReadonlyArray<Pace | null | undefined> | null | undefined;
        readonly id: any;
        readonly location: string | null | undefined;
        readonly name: string | null | undefined;
        readonly profiles: {
          readonly avatar_url: string | null | undefined;
          readonly id: any;
          readonly name: string | null | undefined;
          readonly user_rolesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly role: UserRole;
              };
            }>;
          } | null | undefined;
        } | null | undefined;
        readonly route: {
          readonly distance: number | null | undefined;
          readonly elevation: number | null | undefined;
          readonly id: any;
          readonly image_url: string | null | undefined;
          readonly name: string | null | undefined;
          readonly url: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"RouteCardFragment">;
        } | null | undefined;
        readonly terrain: Terrains | null | undefined;
        readonly time: any | null | undefined;
        readonly type: EventType | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"EventDetailActions_event">;
      };
    }>;
  } | null | undefined;
};
export type EventShareQuery = {
  response: EventShareQuery$data;
  variables: EventShareQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terrain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groups",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elevation",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distance",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar_url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventShareQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "eventsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": "route",
                    "args": null,
                    "concreteType": "routes",
                    "kind": "LinkedField",
                    "name": "routes",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RouteCardFragment"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EventDetailActions_event"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "profiles",
                    "kind": "LinkedField",
                    "name": "profiles",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v15/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user_rolesConnection",
                        "kind": "LinkedField",
                        "name": "user_rolesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user_rolesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "user_roles",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventShareQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "eventsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": "route",
                    "args": null,
                    "concreteType": "routes",
                    "kind": "LinkedField",
                    "name": "routes",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strava_id",
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "profiles",
                    "kind": "LinkedField",
                    "name": "profiles",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user_rolesConnection",
                        "kind": "LinkedField",
                        "name": "user_rolesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user_rolesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "user_roles",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v17/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "attendancesConnection",
                    "kind": "LinkedField",
                    "name": "attendancesCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "attendancesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "attendances",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "user_id",
                                "storageKey": null
                              },
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4de2aa69627cce8c659ec5c937b1db1a",
    "id": null,
    "metadata": {},
    "name": "EventShareQuery",
    "operationKind": "query",
    "text": "query EventShareQuery(\n  $id: BigInt!\n) {\n  eventsCollection(filter: {id: {eq: $id}}, first: 1) {\n    edges {\n      node {\n        id\n        name\n        date\n        type\n        terrain\n        time\n        groups\n        location\n        description\n        route: routes {\n          id\n          name\n          elevation\n          image_url\n          distance\n          url\n          ...RouteCardFragment\n          nodeId\n        }\n        ...EventDetailActions_event\n        profiles {\n          id\n          avatar_url\n          name\n          user_rolesCollection {\n            edges {\n              node {\n                role\n                nodeId\n              }\n            }\n          }\n          nodeId\n        }\n        nodeId\n      }\n    }\n  }\n}\n\nfragment EventDetailActions_event on events {\n  id\n  type\n  date\n  time\n  ...EventDetailConfirmationModal_event\n  attendancesCollection {\n    edges {\n      node {\n        id\n        user_id\n        nodeId\n      }\n    }\n  }\n}\n\nfragment EventDetailConfirmationModal_event on events {\n  id\n  type\n  date\n  name\n  profiles {\n    name\n    avatar_url\n    user_rolesCollection {\n      edges {\n        node {\n          role\n          nodeId\n        }\n      }\n    }\n    nodeId\n  }\n}\n\nfragment RouteCardFragment on routes {\n  id\n  name\n  elevation\n  image_url\n  distance\n  strava_id\n}\n"
  }
};
})();

(node as any).hash = "87473fd48d8496d275bb6477702dd14b";

export default node;
