import { YStack, Heading, View, Text } from "tamagui";

import { Modal, Pressable } from "react-native";
import { CloseIcon } from "../icons";
import { Button } from "../Button";
import { brandShadow } from "../../../../tamagui.config";

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  onConfirm: () => void;
  title: string;
  subtitle: string;
  yesMessage?: string;
  noMessage?: string;
};

export const ConfirmationModal = ({
  onCancel,
  onConfirm,
  isOpen,
  subtitle,
  title,
  yesMessage = "DELETE",
  noMessage = "CANCEL",
}: Props) => {
  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onCancel();
      }}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      transparent={true}
      animationType="fade"
    >
      <View
        flex={1}
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgba(0,0,0,0.5)"
        position="relative"
        p="$4"
        onPress={onCancel}
      >
        <View
          backgroundColor="$brandParchment"
          borderRadius={8}
          p="$5"
          gap="$4"
          {...brandShadow}
        >
          <View justifyContent="center" alignItems="center" pos="relative">
            <Pressable
              onPress={onCancel}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <CloseIcon width={20} height={20} />
            </Pressable>
            <Heading size="$3" textAlign="center" w="100%">
              {title}
            </Heading>
          </View>

          <Text textAlign="center" fontSize={18}>
            {subtitle}
          </Text>
          <YStack
            mt="$2"
            gap="$2"
            justifyContent="center"
            w="100%"
            alignItems="center"
          >
            <Button
              w="100%"
              backgroundColor="red"
              color="white"
              pressStyle={{
                bg: "red",
              }}
              onPress={onConfirm}
            >
              <Text
                justifyContent="center"
                textAlign="center"
                alignItems="center"
                w="100%"
                color="white"
                fontSize={20}
                fontFamily="$heading"
              >
                {yesMessage}
              </Text>
            </Button>
            <Button
              w="100%"
              bg="$brandParchment"
              onPress={onCancel}
              pressStyle={{
                bg: "$brandParchment",
              }}
            >
              <Text
                justifyContent="center"
                textAlign="center"
                alignItems="center"
                w="100%"
                fontSize={20}
                fontFamily="$heading"
              >
                {noMessage}
              </Text>
            </Button>
          </YStack>
        </View>
      </View>
    </Modal>
  );
};
