import { SafeAreaView } from "react-native-safe-area-context";
import { customColorTokens } from "../../../../tamagui.config";
import { Heading, View, XStack } from "tamagui";
import { useNavigation } from "@react-navigation/native";
import { ArrowLeftIcon } from "../../../components/design-system/icons";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { useAtom } from "jotai";
import { newRideAtom } from "../atoms";

export const Location = () => {
  const navigation = useNavigation();
  const [newRide, setNewRide] = useAtom(newRideAtom);

  const handleLocationPress = (location: string) => {
    setNewRide({
      ...newRide,
      location,
    });

    navigation.goBack();
  };
  return (
    <SafeAreaView
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
    >
      <View flex={1}>
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack
            justifyContent="space-between"
            w="100%"
            mb="$5"
            alignItems="flex-start"
          >
            <View>
              <HeaderButton onPress={() => navigation?.goBack()}>
                <ArrowLeftIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>

        <View px="$4" flex={1}>
          <Heading size="$4" mb="$4">
            Choose a location
          </Heading>
          <GooglePlacesAutocomplete
            onFail={(error) => console.error(error)}
            placeholder="Search A Location"
            fetchDetails={true}
            onPress={(data, details) => {
              if (details?.formatted_address)
                handleLocationPress(details?.formatted_address);
            }}
            query={{
              key: "AIzaSyBaT5yZPPpGIYcONyuBofkVyutJ6Jf3eA0",
            }}
            key="AIzaSyBaT5yZPPpGIYcONyuBofkVyutJ6Jf3eA0"
            styles={{
              textInput: {
                borderWidth: 1,
                paddingVertical: 20,
                paddingHorizontal: 12,
                fontSize: 18,
                height: 60,
                fontFamily: "Souvenir",
              },
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};
