import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { graphql } from "relay-hooks";
import { RoutesListQuery } from "./__generated__/RoutesListQuery.graphql";
import { useAtom } from "jotai";
import {
  ComponentType,
  JSXElementConstructor,
  ReactElement,
  Suspense,
} from "react";
import { Loader } from "../../../../components/design-system/Loader";
import { FlashList } from "@shopify/flash-list";
import { Image, Text, View, XStack, YStack } from "tamagui";
import { Pill } from "../../../../components/design-system/Pill";
import { getFeet, getMiles } from "../../../../features/Routes/utils";
import { ArrowRightIcon } from "../../../../components/design-system/icons/ArrowRightIcon";
import { newRideAtom } from "../../atoms";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { RidesNavigatorParamList } from "../../../../navigators/CreateARideNavigator";
import { FlatList } from "react-native";

type route = {
  readonly node: {
    readonly distance: number | null | undefined;
    readonly elevation: number | null | undefined;
    readonly id: any;
    readonly image_url: string | null | undefined;
    readonly name: string | null | undefined;
    readonly url: string | null | undefined;
  };
};

type RoutesListProps = {
  ListHeaderComponent?: ComponentType<any> | undefined;
  ListFooterComponent?:
    | ComponentType<any>
    | ReactElement<any, string | JSXElementConstructor<any>>
    | null
    | undefined;
  queryRef?: PreloadedQuery<RoutesListQuery> | null;
  isSyncing?: boolean;
  onRefresh?: () => void;
};

export const RoutesList = (props: RoutesListProps) => {
  return (
    <Suspense fallback={<Loader />}>
      {props.queryRef ? (
        <RoutesListInner {...props} queryRef={props.queryRef} />
      ) : null}
    </Suspense>
  );
};

type RoutesListInnerProps = RoutesListProps & {
  queryRef: PreloadedQuery<RoutesListQuery>;
};

const RoutesListInner = ({
  queryRef,
  ListFooterComponent,
  ListHeaderComponent,
  isSyncing,
  onRefresh,
}: RoutesListInnerProps) => {
  const navigation = useNavigation<NavigationProp<RidesNavigatorParamList>>();
  const [rideState, setRideState] = useAtom(newRideAtom);
  const data = usePreloadedQuery<RoutesListQuery>(
    RouteListQueryString,
    queryRef
  );

  const routes = data.routesCollection?.edges;

  const handleRoutePress = (newRoute: route) => {
    setRideState({
      ...rideState,
      route: {
        id: newRoute.node.id,
        name: newRoute.node.name ?? "",
      },
    });
    navigation.goBack();
  };

  return (
    <FlatList
      refreshing={isSyncing}
      onRefresh={onRefresh}
      data={routes}
      keyExtractor={(item) => item.node.id}
      contentContainerStyle={{ paddingBottom: 16 }}
      ItemSeparatorComponent={() => <YStack mt="$4" />}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={
        routes?.length && routes?.length > 0 ? null : ListFooterComponent
      }
      StickyHeaderComponent={ListHeaderComponent ?? undefined}
      // stickyHeaderIndices={[0]}
      renderItem={({ item }: { item: route }) => (
        <View px="$4" mb="$4">
          <YStack
            borderWidth={1}
            borderColor="black"
            borderRadius={15}
            bg="$brandParchment"
            onPress={() => handleRoutePress(item)}
            pos="relative"
            pressStyle={{
              scaleX: 0.99,
              scaleY: 0.99,
              shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
              shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
              // Keep the shadow radius unchanged or adjust slightly if needed
            }}
            {...{
              shadowColor: "black",
              shadowOffset: { width: 2, height: 2 },
              shadowOpacity: 1,
              shadowRadius: 0,
            }}
            // onPress={handlePress}
            // overflow="hidden"
          >
            <View
              w="100%"
              overflow="hidden"
              pos="relative"
              borderTopRightRadius={15}
              borderTopLeftRadius={15}
              borderBottomColor={"black"}
              borderBottomWidth={1}
            >
              <Image
                source={{ uri: item?.node.image_url ?? "" }}
                w="100%"
                resizeMode="cover"
                height={225}
              />
            </View>
            <YStack
              alignItems="flex-start"
              justifyContent="space-between"
              px="$4"
              pb={"$4"}
              pt="$4"
              gap="$2"
            >
              <Text fontFamily="$italicHeadingFont" fontSize={20}>
                {item.node.name}
              </Text>

              <XStack w="100%">
                <XStack gap="$2" flex={1}>
                  <Pill
                    bg="$brandYellow"
                    color="black"
                    borderColor="black"
                    text={`${getMiles(item?.node.distance ?? 0) ?? ""} mi`}
                  />
                  <Pill
                    bg="$brandYellow"
                    color="black"
                    borderColor="black"
                    text={`${getFeet(item.node?.elevation ?? 0)} ft`}
                  />
                </XStack>

                <ArrowRightIcon width={20} />
              </XStack>
            </YStack>
          </YStack>
        </View>
      )}
    />
  );
};

export const RouteListQueryString = graphql`
  query RoutesListQuery($profileId: UUID!, $searchTerm: String) {
    routesCollection(
      filter: { profile_id: { eq: $profileId }, name: { ilike: $searchTerm } }
      orderBy: { strava_created_at: DescNullsLast }
    ) {
      edges {
        node {
          id
          name
          elevation
          image_url
          distance
          url
        }
      }
    }
  }
`;
