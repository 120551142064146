/**
 * @generated SignedSource<<c1f045ddc6034357ce75ca2071295b15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "Babes" | "CentralMachineWorks" | "ClubRide" | "CommunityEvent" | "CommunityRide" | "GravelGang" | "PopUp" | "SwimClub" | "TheMeteorCafe" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventDetailActions_event$data = {
  readonly attendancesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: any;
        readonly user_id: any | null | undefined;
      };
    }>;
  } | null | undefined;
  readonly date: any | null | undefined;
  readonly id: any;
  readonly time: any | null | undefined;
  readonly type: EventType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EventDetailConfirmationModal_event">;
  readonly " $fragmentType": "EventDetailActions_event";
};
export type EventDetailActions_event$key = {
  readonly " $data"?: EventDetailActions_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventDetailActions_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventDetailActions_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventDetailConfirmationModal_event"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "attendancesConnection",
      "kind": "LinkedField",
      "name": "attendancesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "attendancesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "attendances",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};
})();

(node as any).hash = "566bb544629fb96d952955d7f8f3fcb5";

export default node;
