import { supabase } from "../../../../../lib/supabase";
import { useState } from "react";

export const useDeleteEvent = (callback: () => void) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteEvent = async (id: string) => {
    setLoading(true);
    console.log("deleting event", id);
    supabase
      .from("events")
      .delete()
      .eq("id", id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        callback();
      });
  };

  return [handleDeleteEvent, loading] as const;
};
