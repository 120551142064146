import React from "react";
import { StyleSheet, Platform } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  interpolate,
} from "react-native-reanimated";
import {
  GestureHandlerRootView,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from "react-native-gesture-handler";
import { View } from "tamagui";
import { brandShadow } from "../../tamagui.config";

type CardFlipProps = {
  front: React.ReactNode;
  back: React.ReactNode;
  isProfileLoaded?: boolean;
};

export const CardFlip = ({ front, back }: CardFlipProps) => {
  const spin = useSharedValue(0);
  const scale = useSharedValue(1);

  const rStyle = useAnimatedStyle(() => {
    const spinVal = interpolate(spin.value, [0, 1], [0, 180]);
    return {
      transform: [
        { perspective: 1000 },
        { scale: scale.value },
        { rotateY: withSpring(`${spinVal}deg`) },
      ],
      shadowOpacity: spin.value ? 0.2 : 0.5,
    };
  });

  const bStyle = useAnimatedStyle(() => {
    const spinVal = interpolate(spin.value, [0, 1], [180, 360]);
    return {
      transform: [
        { perspective: 1000 },
        { scale: scale.value },
        { rotateY: withSpring(`${spinVal}deg`) },
      ],
      shadowOpacity: spin.value ? 0.5 : 0.2,
    };
  });

  const cardHeight = 400;

  const onGestureEvent = (event: PanGestureHandlerGestureEvent) => {
    const { velocityX } = event.nativeEvent;
    if (Math.abs(velocityX) > 200) {
      spin.value = spin.value ? 0 : 1;
    }
  };

  const handlePressIn = () => {
    scale.value = 0.98; // Slightly scale down the card on press
  };

  const handlePressOut = () => {
    scale.value = 1; // Return to original scale
  };

  const handlePress = () => {
    spin.value = spin.value ? 0 : 1;
    handlePressOut(); // Ensure the card scales back up on press
  };

  return (
    <>
      <GestureHandlerRootView>
        <View
          flex={1}
          w="100%"
          justifyContent="center"
          alignItems="center"
          zIndex={1000}
        >
          <PanGestureHandler onGestureEvent={onGestureEvent}>
            <View
              pos="relative"
              w="100%"
              justifyContent="center"
              alignItems="center"
              mb="$4"
              onPressIn={handlePressIn}
              onPressOut={handlePressOut}
              onPress={handlePress}
              perspective={1000}
              $platform-native={{ ...brandShadow }}
            >
              <Animated.View
                style={[
                  Styles.front,
                  rStyle,
                  { height: cardHeight },
                  Platform.OS === "web" ? brandShadow : {},
                ]}
              >
                {front}
              </Animated.View>
              <Animated.View
                style={[
                  Styles.back,
                  bStyle,
                  { height: cardHeight },
                  Platform.OS === "web" ? brandShadow : {},
                ]}
              >
                {back}
              </Animated.View>
            </View>
          </PanGestureHandler>
        </View>
      </GestureHandlerRootView>
    </>
  );
};

const Styles = StyleSheet.create({
  front: {
    width: 330,
    backgroundColor: "#D8D9CF",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    overflow: "hidden",
    borderWidth: 1,
  },
  back: {
    width: 330,
    borderRadius: 20,
    overflow: "hidden",
    backgroundColor: "#FEFBF5",
    backfaceVisibility: "hidden",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    zIndex: 10,
    paddingHorizontal: 8,
  },
  // Add any additional styles you need
});
