import { graphql } from "relay-hooks";
import { useMutation } from "react-relay";
import {
  eventsInsertInput,
  useCreateRideMutation,
} from "./__generated__/useCreateRideMutation.graphql";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import {
  communityRidesQueryAtom,
  myScheduleQueryAtom,
} from "../../../features/Rides/atoms/callbacks";

export const useCreateRide = (callback: (eventId: string) => void) => {
  const currentUser = useAtomValue(currentUserAtom);
  const [createRide, isCreating] = useMutation<useCreateRideMutation>(
    useCreateRideMutationString
  );

  const refreshMyScheduleQuery = useAtomValue(myScheduleQueryAtom);
  const refreshCommunityRides = useAtomValue(communityRidesQueryAtom);

  const handleCreateRide = (input: eventsInsertInput) => {
    createRide({
      variables: {
        input: [input],
        userId: currentUser?.id,
      },
      onCompleted: (res) => {
        if (res.insertIntoeventsCollection?.records.length) {
          const ride = res.insertIntoeventsCollection.records[0];
          callback(ride.id);
          setTimeout(() => {
            refreshCommunityRides?.();
            refreshMyScheduleQuery?.();
          }, 500);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return [handleCreateRide, isCreating] as const;
};

export const useCreateRideMutationString = graphql`
  mutation useCreateRideMutation(
    $input: [eventsInsertInput!]!
    $userId: UUID!
  ) {
    insertIntoeventsCollection(objects: $input) {
      records {
        id
        name
        created_at
        attendancesCollection(filter: { user_id: { eq: $userId } }) {
          edges {
            node {
              id
              events {
                id
                date
              }
            }
          }
        }
      }
    }
  }
`;
