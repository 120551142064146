import { graphql } from "relay-hooks";
import { useQueryLoader } from "react-relay";
import { EventShareQuery } from "./__generated__/EventShareQuery.graphql";
import { Suspense, useEffect } from "react";
import { ImageBackground, Linking } from "react-native";
import { Loader } from "../../../components/design-system/Loader";
import { Heading, ScrollView, View } from "tamagui";
import { BcIcon } from "../../../components/design-system/icons";
// import { EventCard } from "../../../components/design-system/EventCard";
import { Button } from "../../../components/design-system";
import { customColorTokens } from "../../../../tamagui.config";

export const EventShare = ({ eventId }: { eventId: string }) => {
  const [queryRef, loadQuery] = useQueryLoader<EventShareQuery>(
    EventDetailQueryString
  );

  useEffect(() => {
    if (eventId) {
      loadQuery({ id: eventId }, { fetchPolicy: "network-only" });
    }
  }, [loadQuery, eventId]);

  return (
    <ImageBackground
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
      source={require("../../../../assets/pattern.png")}
    >
      <Suspense fallback={<Loader />}>
        {queryRef ? <EventShareInner /> : null}
      </Suspense>
    </ImageBackground>
  );
};

const EventShareInner = () => {
  // const data = usePreloadedQuery(EventDetailQueryString, queryRef);
  // const event = data.eventsCollection?.edges[0]?.node;

  return (
    <ScrollView
      pos="relative"
      overflow="scroll"
      w="100%"
      py={20}
      px="$4"
      $gtMd={{
        justifyContent: "center",
        alignItems: "center",
      }}
      contentContainerStyle={{ flex: 1 }}
    >
      <View
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={20}
        pt="$4"
      >
        <Heading textTransform="uppercase" size="$3" textAlign="center">
          Someone invited you to a ride!
        </Heading>
        <View w={350}>
          {/* {event ? <EventCard disableLink event={event} /> : null} */}
        </View>
        <Button
          bg="yellow"
          onPress={() => Linking.openURL("https://breakfastclubatx.com/club")}
          backgroundColor={customColorTokens.brandYellow}
          hoverStyle={{
            backgroundColor: customColorTokens.brandYellow,
            borderColor: "black",
            borderWidth: 1,
          }}
        >
          JOIN THE CLUB
        </Button>

        <BcIcon width={44} height={44} />
      </View>
    </ScrollView>
  );
};

export const EventDetailQueryString = graphql`
  query EventShareQuery($id: BigInt!) {
    eventsCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          id
          name
          date
          type
          terrain
          time

          groups
          location
          description
          route: routes {
            id
            name
            elevation
            image_url
            distance
            url
            ...RouteCardFragment
          }

          ...EventDetailActions_event

          profiles {
            id
            avatar_url
            name
            user_rolesCollection {
              edges {
                node {
                  role
                }
              }
            }
          }
        }
      }
    }
  }
`;
