/**
 * @generated SignedSource<<735ee681b46a0dadc02b0aef2e53e378>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FollowButtonInternalQuery$variables = {
  currentUserId: any;
  userId: any;
};
export type FollowButtonInternalQuery$data = {
  readonly profile_followersCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: any;
      };
    }>;
  } | null | undefined;
};
export type FollowButtonInternalQuery = {
  response: FollowButtonInternalQuery$data;
  variables: FollowButtonInternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentUserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "followed_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "currentUserId"
          }
        ],
        "kind": "ObjectValue",
        "name": "follower_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FollowButtonInternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profile_followersConnection",
        "kind": "LinkedField",
        "name": "profile_followersCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profile_followersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profile_followers",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FollowButtonInternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profile_followersConnection",
        "kind": "LinkedField",
        "name": "profile_followersCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profile_followersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profile_followers",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "165e1eeb3cc9c9db5afda394ecadff99",
    "id": null,
    "metadata": {},
    "name": "FollowButtonInternalQuery",
    "operationKind": "query",
    "text": "query FollowButtonInternalQuery(\n  $currentUserId: UUID!\n  $userId: UUID!\n) {\n  profile_followersCollection(filter: {follower_id: {eq: $currentUserId}, followed_id: {eq: $userId}}, first: 1) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "008e965c51f4ef41c9c675bd2046f691";

export default node;
