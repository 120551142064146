/**
 * @generated SignedSource<<e17f0c17f4bc289b2e0df07a924c9994>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "Member" | "PaidMember" | "RideLeader" | "Team" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventDetailAttendeesModal_event$data = {
  readonly attendancesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: any;
        readonly profiles: {
          readonly avatar_url: string | null | undefined;
          readonly id: any;
          readonly name: string | null | undefined;
          readonly user_rolesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly role: UserRole;
              };
            }>;
          } | null | undefined;
        } | null | undefined;
        readonly user_id: any | null | undefined;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "EventDetailAttendeesModal_event";
};
export type EventDetailAttendeesModal_event$key = {
  readonly " $data"?: EventDetailAttendeesModal_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventDetailAttendeesModal_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventDetailAttendeesModal_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "attendancesConnection",
      "kind": "LinkedField",
      "name": "attendancesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "attendancesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "attendances",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "profiles",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar_url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user_rolesConnection",
                      "kind": "LinkedField",
                      "name": "user_rolesCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user_rolesEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "user_roles",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "role",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};
})();

(node as any).hash = "b83e2f93408a8d295d77a249f7abbc46";

export default node;
