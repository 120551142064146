/**
 * @generated SignedSource<<f8f6df4d6a7e212d1112796c989159e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "Member" | "PaidMember" | "RideLeader" | "Team" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileCard_fragment$data = {
  readonly avatar_url: string | null | undefined;
  readonly bio: string | null | undefined;
  readonly breakfast_food: string | null | undefined;
  readonly id: any;
  readonly instagram: string | null | undefined;
  readonly name: string | null | undefined;
  readonly strava_code: string | null | undefined;
  readonly user_rolesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly role: UserRole;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "ProfileCard_fragment";
};
export type ProfileCard_fragment$key = {
  readonly " $data"?: ProfileCard_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileCard_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCard_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "strava_code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breakfast_food",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagram",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "user_rolesConnection",
      "kind": "LinkedField",
      "name": "user_rolesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "user_rolesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "user_roles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "profiles",
  "abstractKey": null
};

(node as any).hash = "0f7a5d921bfe9e14f2c9be0db78a151c";

export default node;
