import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomeTabsNavigator } from "./HomeTabsNavigator";

import { UnauthenticatedNavigator } from "./UnauthenticatedNavigator";
import { useAtom, useSetAtom } from "jotai";
import {
  currentUserAtom,
  currentUserProfileAtom,
  currentUserRoleAtom,
  isAuthenticatedAtom,
} from "../../atoms/global";
import { useEffect, useState } from "react";
import { supabase } from "../../lib/supabase";
import { Loader } from "../components/design-system/Loader";
import mixpanel, { setName } from "../../mixpanel";
import { EventDetailQueryRenderer } from "../screens/Events/EventDetail";
import { MemberQueryRenderer } from "../screens/Member/MemberQueryRenderer";
import { EventShareScreen } from "../screens/Events/EventShareScreen";
import { Share } from "../screens/CreateRide";

export type BaseNavigatorParamList = {
  Onboarding?: { screen: string };
  Home: undefined;
  UnauthenticatedNavigator: undefined;
  EventDetail: { eventId: string };
  EventShare: { eventId: string };
  ShareRide: { eventId: string };
  ComingSoon: undefined;
  UserProfile: { userId: string };
  MemberProfile: { id: string };
};

const Stack = createNativeStackNavigator<BaseNavigatorParamList>();

export const BaseNavigator = () => {
  const [isAuthed, setIsAuthed] = useAtom(isAuthenticatedAtom);
  const [loading, setLoading] = useState(true);
  const setCurrentUser = useSetAtom(currentUserAtom);
  const setProfileData = useSetAtom(currentUserProfileAtom);
  const setUserRole = useSetAtom(currentUserRoleAtom);

  const checkAuth = async () => {
    const { data } = await supabase.auth.getSession();
    const hasSessionUser = !!data.session?.user;

    setCurrentUser(data.session?.user ?? null);

    if (hasSessionUser) {
      /// fetch profiles
      const res = await supabase
        .from("profiles")
        .select("*")
        .eq("id", data.session?.user?.id)
        .single();

      const profileData: {
        name: string;
        breakfast_food: string;
        avatar_url: string;
        bio: string;
        instagram: string;
      } = res.data;

      setProfileData(profileData);

      /// fetch role

      const roleRes = await supabase
        .from("user_roles")
        .select("*")
        .eq("user_id", data.session?.user?.id)
        .single();

      setUserRole(roleRes.data?.role ?? null);

      setIsAuthed(true);

      mixpanel.identify(data.session?.user?.id ?? "");
      setName(profileData?.name ?? "");
    }

    return hasSessionUser;
  };

  useEffect(() => {
    checkAuth().then(() => setLoading(false));
  }, [checkAuth]);

  if (loading) return <Loader />;

  return (
    <Stack.Navigator>
      <>
        {isAuthed ? (
          <Stack.Screen
            name="Home"
            options={{
              animation: "fade",
              headerShown: false,
            }}
            component={HomeTabsNavigator}
          />
        ) : (
          <Stack.Screen
            options={{ headerShown: false, title: "Onboarding" }}
            name="UnauthenticatedNavigator"
            component={UnauthenticatedNavigator}
          />
        )}
        {/* Event detail is here so we can have a modal */}
        <Stack.Screen
          name="EventDetail"
          options={{
            headerShown: false,
            title: "Events",
          }}
          component={EventDetailQueryRenderer}
        />

        <Stack.Screen
          name="ShareRide"
          options={{
            headerShown: false,
            title: "Events",
            animation: "fade",
            presentation: "fullScreenModal",
          }}
          component={Share}
        />
        {/* Web Event Share */}
        <Stack.Screen
          name="EventShare"
          options={{
            headerShown: false,
            title: "Events",
            animation: "fade",
            presentation: "fullScreenModal",
          }}
          component={EventShareScreen}
        />

        <Stack.Screen
          name="MemberProfile"
          component={MemberQueryRenderer}
          options={{
            presentation: "transparentModal",
            animation: "fade",
            headerShown: false,
          }}
        />
      </>
    </Stack.Navigator>
  );
};
