import { useQueryLoader } from "react-relay";
import {
  FollowButtonInternal,
  FollowButtonInternalQueryString,
} from "./FollowButtonInternal";
import { FollowButtonInternalQuery } from "./__generated__/FollowButtonInternalQuery.graphql";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../atoms/global";
import { Suspense, useEffect } from "react";

export const FollowButton = ({ userId }: { userId: string }) => {
  const [queryRef, loadQuery] = useQueryLoader<FollowButtonInternalQuery>(
    FollowButtonInternalQueryString
  );

  const currentUser = useAtomValue(currentUserAtom);

  useEffect(() => {
    if (userId && currentUser?.id) {
      loadQuery({
        userId,
        currentUserId: currentUser.id,
      });
    }
  }, [currentUser?.id, userId, loadQuery]);

  const reloadQuery = () => {
    if (userId && currentUser?.id) {
      loadQuery({
        userId,
        currentUserId: currentUser.id,
      });
    }
  };

  return (
    <Suspense fallback={null}>
      {queryRef ? (
        <FollowButtonInternal
          userId={userId}
          queryRef={queryRef}
          reloadQuery={reloadQuery}
        />
      ) : null}
    </Suspense>
  );
};
