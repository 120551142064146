import { useMutation } from "react-relay";
import { graphql } from "relay-runtime";
import { useUpdateProfileMutation } from "./__generated__/useUpdateProfileMutation.graphql";

export const useUpdateProfile = (callback: () => void) => {
  const [updateProfileMutation, isUpdating] =
    useMutation<useUpdateProfileMutation>(useUpdateProfileMutationString);

  const handleUpdateProfile = (
    input: useUpdateProfileMutation["variables"]["input"],
    id: useUpdateProfileMutation["variables"]["id"]
  ) => {
    console.log(input);
    updateProfileMutation({
      variables: {
        id,
        input,
      },
      onCompleted: () => {
        callback();
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return [handleUpdateProfile, isUpdating] as const;
};

export const useUpdateProfileMutationString = graphql`
  mutation useUpdateProfileMutation($input: profilesUpdateInput!, $id: UUID!) {
    updateprofilesCollection(
      set: $input
      filter: { id: { eq: $id } }
      atMost: 1
    ) {
      records {
        ...ProfileCard_fragment

        emergency_contact_name
        emergency_contact_number
        strava_code
      }
    }
  }
`;
