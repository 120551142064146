/**
 * @generated SignedSource<<9a627aa76642bbdc8e9c18c8fd3a43c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "Babes" | "CentralMachineWorks" | "ClubRide" | "CommunityEvent" | "CommunityRide" | "GravelGang" | "PopUp" | "SwimClub" | "TheMeteorCafe" | "%future added value";
export type UserRole = "Member" | "PaidMember" | "RideLeader" | "Team" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventDetailConfirmationModal_event$data = {
  readonly date: any | null | undefined;
  readonly id: any;
  readonly name: string | null | undefined;
  readonly profiles: {
    readonly avatar_url: string | null | undefined;
    readonly name: string | null | undefined;
    readonly user_rolesCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly role: UserRole;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly type: EventType | null | undefined;
  readonly " $fragmentType": "EventDetailConfirmationModal_event";
};
export type EventDetailConfirmationModal_event$key = {
  readonly " $data"?: EventDetailConfirmationModal_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventDetailConfirmationModal_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventDetailConfirmationModal_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "profiles",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "user_rolesConnection",
          "kind": "LinkedField",
          "name": "user_rolesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "user_rolesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user_roles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};
})();

(node as any).hash = "62960e9185cfd1e1f7df764234c53a13";

export default node;
