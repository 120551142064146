import { graphql } from "relay-runtime";
import { EventDetailAttendeesModal_event$key } from "./__generated__/EventDetailAttendeesModal_event.graphql";
import { useFragment } from "react-relay";
import { Modal } from "react-native";
import { ScrollView, Text, View } from "tamagui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { HeaderButton } from "../../../../components/design-system/Button/HeaderButton";
import { customColorTokens } from "../../../../../tamagui.config";
import { CloseIcon } from "../../../../components/design-system/icons";
import { MemberListItem } from "../../../../components/MemberListItem";

type EventDetailAttendeesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fragmentRef: EventDetailAttendeesModal_event$key | null;
};
export const EventDetailAttendeesModal = ({
  isOpen,
  onClose,
  fragmentRef,
}: EventDetailAttendeesModalProps) => {
  const data = useFragment(
    EventDetailAttendeesModalFragmentString,
    fragmentRef
  );

  const { top } = useSafeAreaInsets();

  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
      animationType="fade"
    >
      <View flex={1} pos="relative" backgroundColor="$brandParchment">
        <View top={top} pos="absolute" left="$4" zIndex={1000}>
          <HeaderButton onPress={() => onClose()}>
            <CloseIcon width={16} height={16} />
          </HeaderButton>
        </View>
        <View paddingTop={top + 72} px="$4" flex={1}>
          <Text fontSize={20} fontFamily="$heading">
            ATTENDEES
          </Text>
          <ScrollView>
            {data?.attendancesCollection?.edges.map((e, i) => (
              <MemberListItem
                key={i}
                id={e.node.profiles?.id ?? ""}
                name={e.node.profiles?.name ?? ""}
                role={
                  e.node.profiles?.user_rolesCollection?.edges?.[0]?.node
                    .role ?? "Member"
                }
                avatarUrl={e.node.profiles?.avatar_url ?? null}
                hideArrow
                onPress={() => {}}
              />
            ))}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export const EventDetailAttendeesModalFragmentString = graphql`
  fragment EventDetailAttendeesModal_event on events {
    attendancesCollection {
      edges {
        node {
          id
          user_id
          profiles {
            id
            name
            avatar_url

            user_rolesCollection {
              edges {
                node {
                  role
                }
              }
            }
          }
        }
      }
    }
  }
`;
