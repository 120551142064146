import { atom } from "jotai";
import { Pace } from "../../Events/EventDetail/__generated__/EventDetailQuery.graphql";

type Ride = {
  name?: string | null;
  description?: string | null;
  date?: string | null;
  time?: string | null;
  type?: "ClubRide" | null;
  location?: string | null;
  groups?: Pace | null;
  terrain?: "Road" | "Gravel" | "MTB" | "Mixed";
  route?: { id: string | null; name: string | null };
};

const defaultRide: Ride = {
  name: null,
  description: null,
  date: null,
  time: null,
  type: "ClubRide",
  location: null,
  groups: null,
  terrain: "Road",
  route: undefined,
};

export const newRideAtom = atom<Ride | null>(defaultRide);
