import { graphql } from "relay-hooks";
import { EventDetailActions_event$key } from "./__generated__/EventDetailActions_event.graphql";
import { useFragment } from "react-relay";
import { format, isBefore, parse, startOfToday } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../../atoms/global";
import { View } from "tamagui";
import { Button } from "../../../../components/design-system";
import { useJoinRide } from "../hooks/useJoinRide";
import { useLeaveRide } from "../hooks/useLeaveRide";
import { EventDetailConfirmationModal } from "./EventDetailConfirmationModal";

export const EventDetailActions = ({
  fragmentRef,
  refreshQuery,
  copyUrl,
}: {
  fragmentRef: EventDetailActions_event$key;
  copyUrl: () => void;
  refreshQuery: () => void;
}) => {
  const event = useFragment(EventDetailActionsFragmentString, fragmentRef);

  const dateTimeString = `${event.date}T${event.time ?? "00:00:00"}`;

  // Parse the combined string
  const parsedDateTime = parse(
    dateTimeString,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );

  const now = new Date();

  // Check if the parsed date is before now
  const isEventInThePast = isBefore(parsedDateTime, now);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const currentUser = useAtomValue(currentUserAtom);

  const currentUserAttendance = useMemo(
    () =>
      event?.attendancesCollection?.edges.find(
        (a) => a.node.user_id === currentUser?.id
      ),
    [event?.attendancesCollection?.edges, currentUser?.id]
  );
  const [isUserAttending, setIsUserAttending] = useState(
    !!currentUserAttendance
  );

  useEffect(() => {
    setIsUserAttending(!!currentUserAttendance);
  }, [setIsUserAttending, currentUserAttendance]);

  const [handleJoinRide, isJoiningRide] = useJoinRide(() => {
    setIsUserAttending(true);
    refreshQuery();
    setIsConfirmationModalOpen(true);
  });

  const onJoinRidePress = () => {
    handleJoinRide({
      user_id: currentUser?.id,
      event_id: event?.id,
    });
  };

  const [handleLeaveRide, isLeavingRide] = useLeaveRide(() => {
    setIsUserAttending(false);
    refreshQuery();
  });

  const onLeaveRidePress = () => {
    if (currentUserAttendance?.node.id) {
      handleLeaveRide({
        id: { eq: parseInt(currentUserAttendance.node.id) },
      });
    }
  };

  const isDisabled = isJoiningRide || isLeavingRide || isEventInThePast;

  return (
    <>
      <View
        bg="$brandParchment"
        borderTopWidth={1}
        p="$4"
        pb="$0"
        position="absolute"
        bottom={0}
        w={"100%"}
        paddingBottom={20}
      >
        {isUserAttending ? (
          <Button
            opacity={isDisabled ? 0.5 : 1}
            fontSize={20}
            disabled={isDisabled}
            bg="red"
            color="white"
            onPress={onLeaveRidePress}
            // variant="danger"
          >
            LEAVE
          </Button>
        ) : (
          <Button
            disabled={isDisabled}
            fontSize={20}
            opacity={isDisabled ? 0.5 : 1}
            onPress={onJoinRidePress}
          >
            RSVP
          </Button>
        )}
      </View>
      <EventDetailConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        fragmentRef={event}
        copyUrl={copyUrl}
      />
    </>
  );
};

const EventDetailActionsFragmentString = graphql`
  fragment EventDetailActions_event on events {
    id
    type
    date
    time
    ...EventDetailConfirmationModal_event
    attendancesCollection {
      edges {
        node {
          id
          user_id
        }
      }
    }
  }
`;
