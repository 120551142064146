import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../atoms/global";
import { supabase } from "../../lib/supabase";
import { useState } from "react";

export const useFollowUser = () => {
  const currentUser = useAtomValue(currentUserAtom);

  const [isLoading, setIsLoading] = useState(false);

  const handleFollowUser = async (userId: string, callback: () => void) => {
    setIsLoading(true);
    await supabase
      .from("profile_followers")
      .insert([{ followed_id: userId, follower_id: currentUser?.id }]);

    callback();
    setIsLoading(false);
  };

  return [handleFollowUser, isLoading] as const;
};
