import { graphql } from "relay-hooks";
import { RouteCardFragment$key } from "./__generated__/RouteCardFragment.graphql";
import { useFragment } from "react-relay";
import { Image, View, YStack } from "tamagui";
import { Linking } from "react-native";

type RouteCardProps = {
  fragmentRef: RouteCardFragment$key;
};
export const RouteCard = ({ fragmentRef }: RouteCardProps) => {
  const data = useFragment(RouteCardFragment, fragmentRef);

  return (
    <YStack
      w="100%"
      onPress={() =>
        Linking.openURL(`https://strava.com/routes/${data.strava_id}`)
      }
    >
      <View width={"100%"}>
        <View w="100%" overflow="hidden" pos="relative">
          <Image
            source={{ uri: data?.image_url ?? "" }}
            w="100%"
            resizeMode="cover"
            height={225}
          />
        </View>
      </View>
    </YStack>
  );
};

export const RouteCardFragment = graphql`
  fragment RouteCardFragment on routes {
    id
    name
    elevation
    image_url
    distance
    strava_id
  }
`;
