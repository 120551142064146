import { Button as TamaguiButton, styled } from "tamagui";
import { brandShadow } from "../../../../tamagui.config";

export const Button = styled(TamaguiButton, {
  name: "Button",
  color: "black",
  backgroundColor: "$brandYellow",
  fontFamily: "$heading",
  fontSize: 24,
  borderColor: "black",
  py: 10,
  height: "auto",
  ...brandShadow,
  textAlign: "center",
  justifyContent: "center",
  pressStyle: {
    shadowOffset: { width: 0, height: 0 },
    backgroundColor: "$brandYellow",
    borderWidth: 1,
    borderColor: "black",
  },

  variants: {
    variant: {
      secondary: {
        backgroundColor: "$brandParchment",
        color: "black",
        borderWidth: 1,
        borderColor: "black",
        shadowColor: "black",
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.25,
        fontFamily: "$body",
        py: "$1",
        px: "$2",
      },
      primary: {
        backgroundColor: "$brandYellow",
        color: "black",
        borderWidth: 1,
        borderColor: "black",
        shadowColor: "black",
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.25,
        fontFamily: "$body",
        py: "$1",
        px: "$2",
      },
      danger: {
        backgroundColor: "$red10Dark",
        pressStyle: {
          backgroundColor: "$red10Dark",
        },
      },
    },
  },
});
