/**
 * @generated SignedSource<<cb1fd40391c49f3dce74ebaa60112c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventType = "Babes" | "CentralMachineWorks" | "ClubRide" | "CommunityEvent" | "CommunityRide" | "GravelGang" | "PopUp" | "SwimClub" | "TheMeteorCafe" | "%future added value";
export type Pace = "A" | "B" | "C" | "D" | "Party" | "%future added value";
export type Terrains = "Gravel" | "MTB" | "Mixed" | "Road" | "%future added value";
export type eventsInsertInput = {
  created_at?: any | null | undefined;
  date?: any | null | undefined;
  description?: string | null | undefined;
  groups?: ReadonlyArray<Pace | null | undefined> | null | undefined;
  host_id?: any | null | undefined;
  image_url?: string | null | undefined;
  location?: string | null | undefined;
  name?: string | null | undefined;
  route_id?: any | null | undefined;
  terrain?: Terrains | null | undefined;
  time?: any | null | undefined;
  type?: EventType | null | undefined;
};
export type useCreateRideMutation$variables = {
  input: ReadonlyArray<eventsInsertInput>;
  userId: any;
};
export type useCreateRideMutation$data = {
  readonly insertIntoeventsCollection: {
    readonly records: ReadonlyArray<{
      readonly attendancesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly events: {
              readonly date: any | null | undefined;
              readonly id: any;
            } | null | undefined;
            readonly id: any;
          };
        }>;
      } | null | undefined;
      readonly created_at: any;
      readonly id: any;
      readonly name: string | null | undefined;
    }>;
  } | null | undefined;
};
export type useCreateRideMutation = {
  response: useCreateRideMutation$data;
  variables: useCreateRideMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "user_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "eventsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoeventsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "events",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "attendancesConnection",
                "kind": "LinkedField",
                "name": "attendancesCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "attendancesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "attendances",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "events",
                            "kind": "LinkedField",
                            "name": "events",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateRideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "eventsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoeventsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "events",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "attendancesConnection",
                "kind": "LinkedField",
                "name": "attendancesCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "attendancesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "attendances",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "events",
                            "kind": "LinkedField",
                            "name": "events",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b9c42cfd4a6c63f199fc6fca6bb364e",
    "id": null,
    "metadata": {},
    "name": "useCreateRideMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateRideMutation(\n  $input: [eventsInsertInput!]!\n  $userId: UUID!\n) {\n  insertIntoeventsCollection(objects: $input) {\n    records {\n      id\n      name\n      created_at\n      attendancesCollection(filter: {user_id: {eq: $userId}}) {\n        edges {\n          node {\n            id\n            events {\n              id\n              date\n              nodeId\n            }\n            nodeId\n          }\n        }\n      }\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "488f8244d7c63e79ebf88b1a921261e4";

export default node;
