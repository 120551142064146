import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Provider, useAtomValue, useSetAtom } from "jotai";
import { View } from "tamagui";
import { Route } from "../screens/CreateRide/Route";
import { Information } from "../screens/CreateRide/Information";
import { User } from "@supabase/supabase-js";
import { currentUserAtom } from "../../atoms/global";
import { useEffect } from "react";
import { Location } from "../screens/CreateRide/Location";

export type RidesNavigatorParamList = {
  Route: undefined;
  Location: undefined;
  RideInformation: undefined;
  Share: { eventId: string };
};

const Stack = createNativeStackNavigator<RidesNavigatorParamList>();

export const CreateARideNavigator = () => {
  const currentUser = useAtomValue(currentUserAtom);
  return (
    <View flex={1}>
      <Provider>
        <CreateARideAtomHydrator user={currentUser} />
        <View flex={1}>
          <Stack.Navigator
            screenOptions={{ headerShown: false, title: "CreateARide" }}
          >
            <Stack.Screen name="RideInformation" component={Information} />

            <Stack.Screen name="Route" component={Route} />
            <Stack.Screen name="Location" component={Location} />
          </Stack.Navigator>
        </View>
      </Provider>
    </View>
  );
};

const CreateARideAtomHydrator = ({ user }: { user: User | null }) => {
  const setCurrentUser = useSetAtom(currentUserAtom);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);
  return null;
};
