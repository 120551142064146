import {
  Heading,
  Label,
  ScrollView,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";
import {
  NavigationProp,
  StackActions,
  useNavigation,
} from "@react-navigation/native";
import {
  ArrowLeftIcon,
  CloseIcon,
} from "../../../components/design-system/icons";
import { Button, Input, TextArea } from "../../../components/design-system";
import { PillSelect } from "../../../components/design-system/PillSelect";
import { PillSelectOption } from "../../../components/design-system/PillSelect/PillSelect";

import { useAtomValue } from "jotai";
import { newRideAtom } from "../atoms";
import DateTimePicker from "@react-native-community/datetimepicker";

import { useCreateRide } from "../hooks/useCreateRide";
import { Loader } from "../../../components/design-system/Loader";
import {
  Pace,
  Terrains,
  eventsInsertInput,
} from "../hooks/__generated__/useCreateRideMutation.graphql";
import { currentUserAtom } from "../../../../atoms/global";
import { KeyboardAvoidingView } from "react-native";
import { format } from "date-fns";
import { useState } from "react";
import { customColorTokens } from "../../../../tamagui.config";
import { SafeAreaView } from "react-native-safe-area-context";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { Keyboard } from "react-native";
import { BaseNavigatorParamList } from "../../../navigators/BaseNavigator";
import { RidesNavigatorParamList } from "../../../navigators/CreateARideNavigator";

const terrainOptions: PillSelectOption[] = [
  { label: "Road", value: "Road" },
  { label: "Gravel", value: "Gravel" },
  { label: "Mixed", value: "Mixed" },
  { label: "MTB", value: "MTB" },
];
const paceOptions: PillSelectOption[] = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "Party",
    value: "Party",
  },
];

export const Information = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const newRide = useAtomValue(newRideAtom);

  const [name, setName] = useState(newRide?.route?.name ?? "");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [terrain, setTerrain] = useState("");
  const [pace, setPace] = useState("");

  const canSubmit =
    name && newRide?.location && date && time && terrain && pace;

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();
  const craNavgiation =
    useNavigation<NavigationProp<RidesNavigatorParamList>>();

  const createRideCallback = (eventId: string) => {
    navigation.dispatch(StackActions.replace("Home"));

    setTimeout(() => {
      navigation.navigate("EventDetail", { eventId });
    }, 200);
  };

  const [createRide, isCreatingRide] = useCreateRide(createRideCallback);

  const onSubmit = () => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const formattedTime = format(time, "HH:mm:ss");

    const newRideInput: eventsInsertInput = {
      name,
      location: newRide?.location ?? "",
      terrain: terrain as Terrains,
      type: "ClubRide",
      groups: [pace as Pace],
      host_id: currentUser?.id ?? "",
      route_id: newRide?.route?.id ?? "",
      date: formattedDate,
      time: formattedTime,
      description,
    };

    createRide(newRideInput);
  };

  return (
    <ScrollView flex={1} keyboardShouldPersistTaps="handled">
      <SafeAreaView
        style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
      >
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack justifyContent="space-between" w="100%" alignItems="center">
            <View>
              <HeaderButton onPress={() => navigation.goBack()}>
                <CloseIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
            <View justifyContent="flex-end" alignItems="flex-end">
              <Text fontSize={16}>Rides by Breakfast Club</Text>
            </View>
          </XStack>
        </View>
        <KeyboardAvoidingView>
          <View overflow="visible" flex={1}>
            {isCreatingRide ? <Loader /> : null}
            <View flex={1}>
              <YStack gap="$1" flex={1} w="100%" px="$4">
                <View>
                  <Label onPress={() => Keyboard.dismiss()}>Name</Label>
                  <Input
                    placeholder="Name"
                    value={name}
                    onChangeText={(text) => {
                      setName(text);
                    }}
                  />
                </View>
                <View>
                  <Label onPress={() => Keyboard.dismiss()}>Description</Label>
                  <TextArea
                    numberOfLines={4}
                    placeholder="Tell us about the ride! (Stops, etc)"
                    value={description}
                    style={{
                      backgroundColor: "white",
                      borderWidth: 1,
                      borderRadius: 6,
                      borderColor: "black",

                      paddingVertical: 16,
                      paddingHorizontal: 12,
                      fontSize: 18,
                    }}
                    onChangeText={(text) => {
                      setDescription(text);
                    }}
                  />
                </View>
                <View>
                  <Label>Starting Location</Label>
                  <View
                    borderWidth={1}
                    borderRadius={6}
                    borderColor="black"
                    py="$4"
                    px="$3"
                    onPress={() => craNavgiation.navigate("Location")}
                    bg="white"
                  >
                    <Text
                      fontSize={18}
                      color={newRide?.location ? "black" : "rgba(0,0,0,0.70)"}
                    >
                      {newRide?.location ?? "Start Location"}
                    </Text>
                  </View>
                </View>
                <View>
                  <Label>Route</Label>
                  <View
                    borderWidth={1}
                    borderRadius={6}
                    borderColor="black"
                    py="$4"
                    px="$3"
                    onPress={() => craNavgiation.navigate("Route")}
                    bg="white"
                  >
                    <Text
                      fontSize={18}
                      color={newRide?.location ? "black" : "rgba(0,0,0,0.70)"}
                    >
                      {newRide?.route?.name ?? "No route, just vibes"}
                    </Text>
                  </View>
                </View>
                <XStack justifyContent="space-between" mt="$4">
                  <Label onPress={() => Keyboard.dismiss()}>Date</Label>

                  <DateTimePicker
                    testID="dateTimePicker"
                    value={date}
                    mode={"date"}
                    is24Hour={false}
                    onChange={(e, d) => d && setDate(d)}
                  />
                </XStack>
                <XStack justifyContent="space-between">
                  <Label onPress={() => Keyboard.dismiss()}>Time</Label>
                  <DateTimePicker
                    testID="dateTimePicker"
                    value={time}
                    mode={"time"}
                    is24Hour={false}
                    onChange={(e, t) => t && setTime(t)}
                  />
                </XStack>

                <View>
                  <PillSelect
                    label="Terrain"
                    value={terrain}
                    setValue={(string) => setTerrain(string)}
                    options={terrainOptions}
                  />
                </View>
                <View>
                  <PillSelect
                    label="Pace"
                    value={pace}
                    setValue={(string) => setPace(string)}
                    options={paceOptions}
                  />
                </View>
                <Button
                  onPress={onSubmit}
                  mt="$4"
                  disabled={!canSubmit}
                  opacity={canSubmit ? 1 : 0.5}
                >
                  Create Ride
                </Button>
              </YStack>
            </View>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ScrollView>
  );
};
