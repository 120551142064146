import {
  Heading,
  Image,
  ScrollView,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";

import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { MemberQuery } from "./__generated__/MemberQuery.graphql";
import Avatar from "../../components/Avatar";
import { ArrowLeftIcon, StarIcon } from "../../components/design-system/icons";
import { HeaderButton } from "../../components/design-system/Button/HeaderButton";
import { useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { format } from "date-fns";
import { useGetGroupIcon } from "../../features/Events/hooks/useGetEventGroupIcons";
import { brandShadowSm, customColorTokens } from "../../../tamagui.config";
import { FollowButton } from "../../components/FollowButton";
import { Linking } from "react-native";

type MemberProps = {
  queryRef: PreloadedQuery<MemberQuery>;
};

export const Member = ({ queryRef }: MemberProps) => {
  const data = usePreloadedQuery<MemberQuery>(MemberQueryString, queryRef);
  const member = data.profilesCollection?.edges?.[0]?.node;
  const role = member?.user_rolesCollection?.edges?.[0]?.node?.role ?? "Member";
  const roleDisplay =
    role === "PaidMember" ? "Club" : role.split(/(?=[A-Z])/).join(" ");
  const navigation = useNavigation();

  const { top } = useSafeAreaInsets();

  const date = new Date(member?.created_at);

  const formattedDate = format(date, "MM/yy");

  const groupIcons = ["A", "B", "C"].map((g, i) => {
    const Icon = g ? useGetGroupIcon({ group: g }) : null;
    return Icon ? (
      <View
        {...brandShadowSm}
        borderWidth={1}
        borderRadius={999}
        bg="black"
        key={i}
      >
        <Icon
          width={32}
          height={32}
          key={i}
          fill={customColorTokens.brandParchment}
        />
      </View>
    ) : null;
  });

  const followersCount =
    member?.profile_followersCollection?.edges?.length ?? 0;
  return (
    <ScrollView
      w="100%"
      flex={1}
      contentContainerStyle={{
        backgroundColor: "$brandYellow",
      }}
    >
      <View bg="$brandYellow" pt={top}>
        <View
          px="$4"
          pb="$2"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection="row"
        >
          <HeaderButton onPress={() => navigation.goBack()}>
            <ArrowLeftIcon color="black" width={16} height={16} />
          </HeaderButton>
          <FollowButton userId={member?.id} />
        </View>
        <View
          bg="$brandYellow"
          pb="$4"
          px="$4"
          w="100%"
          borderBottomWidth={1}
          justifyContent="center"
          alignItems="center"
        >
          <View pos="relative">
            <Avatar
              size={200}
              imageStyle={{
                borderRadius: 999,
                borderColor: "black",
                borderWidth: 1,
              }}
              url={member?.avatar_url ?? null}
            />
            {role !== "Member" ? (
              <View
                bottom={-8}
                right={-8}
                borderRadius={999}
                justifyContent="space-between"
                alignItems="center"
                pos="absolute"
                bg="$brandParchment"
                p="$2"
                borderWidth={1}
              >
                <StarIcon width={50} height={50} />
                <View
                  pos="absolute"
                  alignItems="center"
                  justifyContent="center"
                  bottom={0}
                  top={0}
                >
                  <Heading
                    fontSize={10}
                    textTransform="uppercase"
                    fontStyle="italic"
                    w={42}
                    textAlign="center"
                  >
                    {roleDisplay}
                  </Heading>
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </View>

      <View flex={1} pos="relative">
        <Image
          src={require("../../../assets/pattern.png")}
          resizeMode="repeat"
          pos="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          opacity={0.125}
        />
        <View
          justifyContent="flex-start"
          pos="relative"
          px="$4"
          pt="$4"
          gap="$2"
        >
          <Heading
            size="$4"
            textTransform="uppercase"
            fontFamily="$heading"
            flexShrink={0}
          >
            {member?.name}
          </Heading>
        </View>

        <View p="$4" gap="$4">
          <Text>About</Text>

          <YStack
            gap="$1"
            bg="$brandYellow"
            borderRadius={20}
            p="$2"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            minHeight={80}
            {...brandShadowSm}
          >
            <Text fontSize={20}>{member?.breakfast_food}</Text>

            <Text fontFamily="$heading" fontSize={10} textTransform="uppercase">
              Favorite Breakfast Food
            </Text>
          </YStack>
          <XStack gap="$4" justifyContent="space-between">
            <View
              bg="$brandYellow"
              borderRadius={20}
              p="$4"
              justifyContent="center"
              alignItems="center"
              borderWidth={1}
              height={80}
              flexGrow={1}
              {...brandShadowSm}
            >
              <Text fontSize={20}>{followersCount}</Text>
              <Text
                fontFamily="$heading"
                textTransform="uppercase"
                fontSize={10}
              >
                Followers
              </Text>
            </View>
            <View
              bg="$brandYellow"
              borderRadius={20}
              borderWidth={1}
              height={80}
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              {...brandShadowSm}
            >
              <Text fontSize={20} zIndex={10}>
                {formattedDate}
              </Text>
              <Text
                fontFamily="$heading"
                textTransform="uppercase"
                fontSize={10}
                zIndex={10}
              >
                Member Since
              </Text>
            </View>
          </XStack>

          <YStack
            gap="$1"
            bg="$brandYellow"
            borderRadius={20}
            p="$2"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            minHeight={80}
            {...brandShadowSm}
          >
            <XStack gap="$2">
              {groupIcons ? (
                groupIcons
              ) : (
                <Text>{`This user doesn't have any favorite groups :(`}</Text>
              )}
            </XStack>

            <Text fontFamily="$heading" fontSize={10} textTransform="uppercase">
              Favorite Groups
            </Text>
          </YStack>

          <Text>Safety</Text>
          {member?.emergency_contact_name && member.emergency_contact_number ? (
            <YStack
              gap="$1"
              bg="$red10Dark"
              borderRadius={20}
              p="$2"
              justifyContent="center"
              alignItems="center"
              borderWidth={1}
              minHeight={80}
              onPress={() =>
                Linking.openURL(`tel:${member?.emergency_contact_number}`)
              }
              {...brandShadowSm}
            >
              <Text fontSize={20} zIndex={10} color="white">
                {member?.emergency_contact_name ?? "Unknown"}
              </Text>
              <Text
                fontFamily="$heading"
                fontSize={10}
                textTransform="uppercase"
                color="white"
              >
                Emergency Contact (Tap to call)
              </Text>
            </YStack>
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
};

export const MemberQueryString = graphql`
  query MemberQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          ...ProfileCard_fragment
          id
          name
          avatar_url
          bio
          created_at
          strava_code
          breakfast_food
          instagram
          member_number
          emergency_contact_name
          emergency_contact_number
          profile_followersCollection {
            edges {
              node {
                id
              }
            }
          }
          favorite_groups
          user_rolesCollection {
            edges {
              node {
                role
              }
            }
          }
        }
      }
    }
  }
`;
